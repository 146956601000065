import React from 'react'
import { graphql } from "gatsby"
import { useSelector } from 'react-redux'
import _ from 'lodash'
import objectDataCombiner from '../utils/objectDataCombiner'


const History = ({data}) => {

  const attempted_mcqs = useSelector((state) => state.attempted_mcqs.ids)

  const attempted_mcqs_objects = _.intersectionWith(data.allMarkdownRemark.edges, attempted_mcqs, ({node}, x )=>node.id == x.id)

  objectDataCombiner(attempted_mcqs, attempted_mcqs_objects)

  

    return (
    <div className='mt-8'>


      {attempted_mcqs_objects.map(edge =>{ 
            return (
            <div key={edge.node.id}>
              <p className={`p-4 shadow-sm ${edge.node.is_given_answer_true ? `bg-green-400` : `bg-red-400`}`}>{edge.node.frontmatter.title}</p>
              <ol>
                {edge.node.frontmatter.options.map(option => (
                  <li
                    className="shadow-lg m-2 shadow-gray-300 p-2 bg-gray-600 bg-opacity-30 "
                    key={option + Math.random()}
                  >
                    {option}
                  </li>
                ))}
              </ol>
            </div>
            )
          }
        )
      }
    </div>
  )
}

export const Historyquery = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "mcq" } } }) {
      edges {
        node {
          frontmatter {
            title
            options
          }
          id
        }
      }
    }
  }
`

export default History